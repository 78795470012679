import React from "react";

import { useData } from "../context/AppContext";

import "./moon.scss";

const Moon = () => {
    const { setIsLightMode } = useData();

    return (
        <svg
            className="moon-svg"
            onClick={() => setIsLightMode(false)}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.7396 10.4421C23.7292 11.307 21.8821 12.5353 20.3046 14.0562C19.641 14.6927 18.457 16.0609 18.1161 16.5831C18.0706 16.649 17.8961 16.9164 17.7268 17.1721C17.3605 17.7199 16.9959 18.3433 16.7078 18.8998C16.4048 19.4849 16.024 20.2832 15.9924 20.3973C15.9774 20.4523 15.8944 20.6774 15.8093 20.8967C13.8088 26.0571 14.0532 31.9778 16.4797 37.1386C18.4289 41.2877 21.6271 44.6327 25.6032 46.6737C28.1473 47.9781 30.7852 48.6722 33.5942 48.7716C34.8812 48.8164 36.2802 48.7089 37.566 48.4589C38.5647 48.2643 39.0462 48.1302 40.131 47.7458C41.1724 47.3802 41.5663 47.2107 42.3371 46.8149C42.5621 46.6987 42.8194 46.5677 42.9091 46.5293C42.9977 46.4879 43.2258 46.3541 43.4149 46.234C45.2144 45.0656 46.1603 44.2893 47.3565 42.9997C48.2307 42.0564 48.8435 41.2588 49.499 40.2105C50.0203 39.3756 50.0589 39.2724 49.8752 39.1782C49.8102 39.1449 49.7347 39.1617 49.201 39.3342C44.3023 40.9007 38.9065 40.1987 34.2855 37.3977C29.5636 34.5329 26.1617 29.6413 25.0122 24.0645C24.0585 19.4466 24.7274 14.6983 26.9011 10.6276C27.1193 10.2186 27.1186 10.2254 27.0831 10.1252C27.0288 9.97199 26.9603 9.97304 26.5052 10.1376C26.2781 10.2214 25.9357 10.3593 25.7396 10.4421Z"
                fill="black"
            />
        </svg>
    );
};

export default Moon;
