import React from "react";

import { useData } from "../context/AppContext";

import "./linkedin.scss";

const Linkedin = () => {
    const { isLightMode } = useData();

    return (
        <svg
            className={`linkedin-svg${isLightMode ? " light-mode" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27 27"
            onClick={() => window.open("https://www.linkedin.com/in/yen-maruf/")}
        >
            <title>Linkedin</title>
            <path d="M7.56,6.86c0,1-.91,1.85-2.32,1.85S3,7.88,3,6.86,3.88,5,5.26,5A2,2,0,0,1,7.56,6.86ZM3.11,22V10.16H7.42V22Z" />
            <path d="M10.87,13.94c0-1.48-.06-2.74-.11-3.78h3.73l.2,1.62h.08a5.35,5.35,0,0,1,4.28-1.89C21.88,9.89,24,11.49,24,15v7H19.7V15.44c0-1.53-.62-2.57-2.18-2.57a2.24,2.24,0,0,0-2.35,2.3V22h-4.3Z" />
        </svg>
    );
};

export default Linkedin;
