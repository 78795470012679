import React from "react";

import { useData } from "../context/AppContext";

import "./email.scss";

const Email = () => {
    const { isLightMode } = useData();

    return (
        <svg
            className={`email-svg${isLightMode ? " light-mode" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27 27"
            fill="none"
        >
            <title>Email</title>
            <polygon points="16.92 13.12 24 18.08 24 7.95 16.92 13.12" />
            <polygon points="3 7.95 3 18.08 10.08 13.12 3 7.95" />
            <path d="M22.69,5H4.31A1.35,1.35,0,0,0,3,6.24L13.5,13.88,24,6.24A1.35,1.35,0,0,0,22.69,5Z" />
            <path d="M15.72,14l-1.86,1.35a.6.6,0,0,1-.72,0L11.28,14,3,19.77A1.35,1.35,0,0,0,4.31,21H22.69A1.35,1.35,0,0,0,24,19.77Z" />
        </svg>
    );
};

export default Email;
