import React, { useEffect, useRef, useState } from "react";

import { useData } from "../../context/AppContext";

import "./about-styles.scss";

const About = () => {
    const { isLightMode } = useData();

    const highlightColor = isLightMode ? "light-green-text" : "cyan-text";
    const textColor = isLightMode ? "black-text" : "white-text";

    // Play animations once only
    const [animationsPlayed, setAnimationsPlayed] = useState(false);

    // References
    const titleRef = useRef(null);
    const details1Ref = useRef(null);
    const details2Ref = useRef(null);
    const details3Ref = useRef(null);
    const dailyRef = useRef(null);
    const dailyDetailsRef1 = useRef(null);
    const dailyDetailsRef2 = useRef(null);

    useEffect(() => {
        let observer;

        if (
            titleRef.current &&
            details1Ref.current &&
            details2Ref.current &&
            details3Ref.current &&
            dailyRef.current &&
            dailyDetailsRef1.current &&
            dailyDetailsRef2.current
        ) {
            const options = {
                threshold: 0.6,
            };

            observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const liElements = entry.target.querySelectorAll("li");

                        if (liElements != null && liElements.length > 0) {
                            liElements.forEach((element) => {
                                if (animationsPlayed) {
                                    element.classList.add("opacity-1");
                                } else {
                                    element.classList.add("fade-up-animation");
                                }
                            });
                        } else {
                            if (animationsPlayed) {
                                entry.target.classList.add("opacity-1");
                            } else {
                                entry.target.classList.add("fade-up-animation");
                            }

                            setAnimationsPlayed(true);
                        }

                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(titleRef.current);
            observer.observe(details1Ref.current);
            observer.observe(details2Ref.current);
            observer.observe(details3Ref.current);
            observer.observe(dailyRef.current);
            observer.observe(dailyDetailsRef1.current);
            observer.observe(dailyDetailsRef2.current);
        }

        return () => observer.disconnect();
        // eslint-disable-next-line
    }, [titleRef, details1Ref, details2Ref, details3Ref, dailyRef, dailyDetailsRef1, dailyDetailsRef2, isLightMode]);

    return (
        <div id="about" className="about-content section-content">
            <h2 className={`${highlightColor} section-header`} ref={titleRef}>
                About Me
            </h2>
            <div className="about-details">
                <p className={textColor} ref={details1Ref}>
                    My programming journey started in mid-2018 when I was researching a career change. I was interested
                    in computers and video games from a young age so naturally, I went into computer science.
                </p>
                <p className={textColor} ref={details2Ref}>
                    For the first two years, I taught myself how to code and established a concrete programming
                    foundation. After learning the basics, abstract and theoretical topics, I applied my knowledge to
                    Android app development and then to full-stack web development. I decided to focus on web
                    development because I enjoy designing and developing websites.
                </p>
                <p className={textColor} ref={details3Ref}>
                    I am currently working as a full-stack engineer designing and developing a web-based DMS (Dealer
                    Management System). The tech stack I am using is React (JS, ES6+) + SpringBoot (Java) + SQL. When I
                    am not pursuing my other hobbies, I enjoy learning about and expanding my skills set as a software
                    engineer through personal projects.
                </p>
            </div>
            <div className="about-daily">
                <h3 className={`${highlightColor}`} ref={dailyRef}>
                    Some Languages, Technologies & Tools I use daily:
                </h3>
                <ul>
                    <div ref={dailyDetailsRef1}>
                        <li className={textColor}>Github</li>
                        <li className={textColor}>Java</li>
                        <li className={textColor}>JavaScript (ES6+)</li>
                    </div>
                    <div ref={dailyDetailsRef2}>
                        <li className={textColor}>React.js</li>
                        <li className={textColor}>SQL</li>
                        <li className={textColor}>Spring Boot</li>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default About;
