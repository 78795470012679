import React from "react";

import MainPage from "./component/main-page/MainPage";

import { AppContext } from "./context/AppContext";

import "./styles/global.scss";
import "./styles/typography.scss";

const App = () => {
    return (
        <AppContext>
            <MainPage />
        </AppContext>
    );
};

export default App;
