import React from "react";

import { useData } from "../context/AppContext";

import "../icon/logo.scss";

const Logo = () => {
    const { isLightMode } = useData();

    return (
        <svg
            className={`logo${isLightMode ? " light-mode" : ""}`}
            viewBox="0 0 60 60"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle id="logo-border" cx="30.5" cy="30.5" r="26" strokeWidth="3" />
            <path
                id="logo-letter"
                d="M28.1381 32.905L18.1931 14.86H22.1081L29.8481 29.44L37.5881 14.86H41.5031L31.6031 32.905V46H28.1381V32.905Z"
            />
            <line id="logo-top-line" x1="21.0005" y1="33" x2="39.0005" y2="33.0093" strokeWidth="2" opacity="0" />
            <line id="logo-bottom-line" x1="21.0005" y1="39" x2="39.0005" y2="39.0093" strokeWidth="2" opacity="0" />
        </svg>
    );
};

export default Logo;
