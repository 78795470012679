import React, { useEffect, useRef, useState } from "react";

import ProjectDetails from "./ProjectDetails";

import { useData } from "../../context/AppContext";

import "./project-styles.scss";

const Projects = () => {
    const { isLightMode } = useData();

    // Themes
    const highlightColor = isLightMode ? "light-green-text" : "cyan-text";
    const textColor = isLightMode ? "black-text" : "white-text";

    // Card details
    const project1 = {
        title: "Income Allocation Calculator",
        body:
            "A calculator that separates an income amount by numbers and/or " +
            "percentages for different categories. Displays the result of the " +
            "total allocation in a pie chart that's easy to read and understand.",
        footer: ["React", "JS/HTML/CSS", "Typescript"],
        link: "https://github.com/yen213/Income-Allocator",
    };

    const project2 = {
        title: "Chat Messaging Android App",
        body:
            "Android chat messaging application which can send, receive, and " +
            "update information in real-time. Users can search for other users, " +
            "send friend requests, group chat, and more.",
        footer: ["Android", "Java", "JS/HTML/CSS"],
        link: "https://github.com/yen213/TextIt",
    };

    const project3 = {
        title: "Chore Tracker",
        body:
            "A simple web app that my housemates and I use to keep track of " +
            "assigned chores and amenities being used. Hosted on Firebase.",
        footer: ["Firebase/Firestore", "React", "JS/HTML/CSS"],
        link: "https://github.com/yen213/whos-where",
    };

    // Play animations once only
    const [animationsPlayed, setAnimationsPlayed] = useState(false);

    // Reference
    const titleRef = useRef(null);

    useEffect(() => {
        let observer;

        if (titleRef.current) {
            const options = {
                threshold: 0.6,
            };

            observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (animationsPlayed) {
                            entry.target.classList.add("opacity-1");
                        } else {
                            entry.target.classList.add("fade-up-animation");
                            setAnimationsPlayed(true);
                        }

                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(titleRef.current);
        }

        return () => observer.disconnect();
        // eslint-disable-next-line
    }, [titleRef, isLightMode]);

    return (
        <div id="projects" className="project-content section-content">
            <h2 className={`${highlightColor} section-header`} ref={titleRef}>
                Personal Projects
            </h2>
            <div className="card-container">
                <div className="project-cards">
                    <div className="card-wrap">
                        <ProjectDetails
                            title={project1.title}
                            body={project1.body}
                            footer={project1.footer}
                            link={project1.link}
                            textColor={textColor}
                            highlightColor={highlightColor}
                        />
                    </div>
                    <div className="card-wrap">
                        <ProjectDetails
                            title={project2.title}
                            body={project2.body}
                            footer={project2.footer}
                            link={project2.link}
                            textColor={textColor}
                            highlightColor={highlightColor}
                        />
                    </div>
                    <div className="card-wrap">
                        <ProjectDetails
                            title={project3.title}
                            body={project3.body}
                            footer={project3.footer}
                            link={project3.link}
                            textColor={textColor}
                            highlightColor={highlightColor}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
