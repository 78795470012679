import React, { useEffect, useRef } from "react";

import Contact from "../contact/Contact";
import Home from "../home/Homes";
import About from "../about/About";
import Menu from "../menu/Menu";
import NavMenu from "../../icon/NavMenu";
import Projects from "../projects/Projects";

import { useData } from "../../context/AppContext";

import "./main-page-styles.scss";

const MainPage = () => {
    const { isLightMode } = useData();

    const selectedColor = isLightMode ? "light-green-text" : "cyan-text";
    const textColor = isLightMode ? "black-text" : "white-text";

    // References to each section
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const projectsRef = useRef(null);
    const contactRef = useRef(null);

    useEffect(() => {
        isLightMode
            ? document.body.classList.add("light-mode-scrollbar")
            : document.body.classList.remove("light-mode-scrollbar");
    }, [isLightMode]);

    useEffect(() => {
        let observer;

        if (homeRef.current && aboutRef.current && projectsRef.current && contactRef.current) {
            const options = {
                threshold: 0.6,
            };

            observer = new IntersectionObserver((entries, _observer) => {
                entries.forEach((entry) => {
                    const entryID = entry.target.id.split("-")[0];
                    const menuElement = document.querySelector(`#${entryID}-link`);
                    const navElement = document.querySelector(`#nav-${entryID}`);

                    // Left menu bar classes
                    if (entry.isIntersecting) {
                        if (!menuElement.classList.contains(selectedColor)) {
                            menuElement.classList.remove(textColor);
                            menuElement.classList.add(selectedColor);
                        }
                    } else if (menuElement.classList.contains(selectedColor)) {
                        menuElement.classList.remove(selectedColor);
                        menuElement.classList.add(textColor);
                    }

                    // Hamburger menu classes
                    if (entry.isIntersecting) {
                        if (!navElement.classList.contains(selectedColor)) {
                            navElement.classList.remove(textColor);
                            navElement.classList.add(selectedColor);
                        }
                    } else if (navElement.classList.contains(selectedColor)) {
                        navElement.classList.remove(selectedColor);
                        navElement.classList.add(textColor);
                    }
                });
            }, options);

            observer.observe(homeRef.current);
            observer.observe(aboutRef.current);
            observer.observe(projectsRef.current);
            observer.observe(contactRef.current);
        }

        return () => observer.disconnect();
    }, [homeRef, aboutRef, projectsRef, contactRef, selectedColor, textColor]);

    return (
        <div className={`main-page-container${isLightMode ? " light-background" : ""}`}>
            <Menu />
            <NavMenu />
            <div className="main-page">
                <section id="home-section" ref={homeRef}>
                    <Home />
                </section>
                <section id="about-section" ref={aboutRef}>
                    <About />
                </section>
                <section id="projects-section" ref={projectsRef}>
                    <Projects />
                </section>
                <section id="contact-section" ref={contactRef}>
                    <Contact />
                </section>
            </div>
        </div>
    );
};

export default MainPage;
