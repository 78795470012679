import React, { useEffect, useRef, useState } from "react";

import { useData } from "../../context/AppContext";

import "component/contact/contact-styles.scss";

const Contact = () => {
    const { isLightMode } = useData();

    const highlightColor = isLightMode ? "light-green-text" : "cyan-text";
    const textColor = isLightMode ? "black-text" : "white-text";

    // Play animations once only
    const [animationsPlayed, setAnimationsPlayed] = useState(false);

    // Reference
    const titleRef = useRef(null);
    const body = useRef(null);
    const contact = useRef(null);
    const footer1 = useRef(null);
    const footer2 = useRef(null);

    useEffect(() => {
        let observer;

        if (
            titleRef.current != null &&
            body.current != null &&
            contact.current != null &&
            footer1.current != null &&
            footer2.current != null
        ) {
            const options = {
                threshold: 0.6,
            };

            observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (animationsPlayed) {
                            entry.target.classList.add("opacity-1");
                        } else {
                            entry.target.classList.add("fade-up-animation");
                            setAnimationsPlayed(true);
                        }

                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(titleRef.current);
            observer.observe(body.current);
            observer.observe(contact.current);
            observer.observe(footer1.current);
            observer.observe(footer2.current);
        }

        return () => observer.disconnect();
        // eslint-disable-next-line
    }, [titleRef, body, contact, footer1, footer2, isLightMode]);

    return (
        <div id="contact" className="contact-content section-content flex-center">
            <h2 className={`${highlightColor} section-header`} ref={titleRef}>
                Get In Touch
            </h2>
            <p className={`${textColor} body`} ref={body}>
                I am currently looking for new opportunities. Please don't hesitate to reach out if you know of an open
                role that I might be a great fit for or if you just want to chat. Thanks for stopping by!
            </p>
            <a
                ref={contact}
                href="mailto:hello@yenmaruf.com"
                rel="noopener noreferrer"
                target="_blank"
                className={`${isLightMode ? "light-mode" : ""}`}
            >
                Contact Me
            </a>
            <div className="flex-center footer">
                <p className={`${isLightMode ? "black-text" : "cyan-text"}`} ref={footer1}>
                    Designed & Built by Yen Maruf
                </p>
                <p className={`${isLightMode ? "black-text" : "cyan-text"}`} ref={footer2}>
                    © Yen Maruf 2022
                </p>
            </div>
        </div>
    );
};

export default Contact;
