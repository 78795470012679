import React, { useEffect, useRef } from "react";

import GithubFull from "../../icon/GithubFull";

import { useData } from "../../context/AppContext";

const ProjectDetails = ({ title, body, footer, link, textColor, highlightColor }) => {
    const { isLightMode } = useData();

    // References
    const titleRef = useRef(null);
    const bodyRef = useRef(null);

    useEffect(() => {
        let observer;

        if (titleRef.current && bodyRef.current) {
            const options = {
                threshold: 0.6,
            };

            observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("opacity-1");
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            observer.observe(titleRef.current);
            observer.observe(bodyRef.current);
        }

        return () => observer.disconnect();
    }, [titleRef, bodyRef, isLightMode]);

    return (
        <div className={`card${isLightMode ? " light-mode" : ""}`}>
            <p className={`${highlightColor} title`} ref={titleRef}>
                {title}
            </p>
            <p className={`${textColor} body`} ref={bodyRef}>
                {body}
            </p>
            <div className="card-space-filler"></div>
            <div className={`${highlightColor} footer`}>
                {footer.map((item, idx) => (
                    <p className={`${isLightMode ? "light-mode" : ""}`} key={idx}>
                        {item}
                    </p>
                ))}
                <div onClick={() => window.open(link)}>
                    <GithubFull />
                </div>
            </div>
        </div>
    );
};

export default ProjectDetails;
