import { createContext, useContext, useState } from "react";

export const createAppContext = () => {
    const context = createContext();

    const useAppContext = () => {
        const c = useContext(context);

        if (!c) {
            throw new Error(
                "useAppContext is not inside a Provider with a value. Check that a component " +
                    "is wrapped with the DataProvider."
            );
        }

        return c;
    };

    return [useAppContext, context.Provider];
};

// Get the context and provider
const [useData, CtxProvider] = createAppContext();

// Custom hook to consume the app context
export { useData };

// Data provider to provide the state and state function to children components
export const AppContext = ({ children }) => {
    const [isLightMode, setIsLightMode] = useState(false);

    return (
        <CtxProvider
            value={{
                isLightMode,
                setIsLightMode,
            }}
        >
            {children}
        </CtxProvider>
    );
};
