import React, { useCallback, useEffect, useState } from "react";

import Logo from "./Logo";
import Moon from "./Moon";
import Sun from "./Sun";

import { useData } from "../context/AppContext";

import "../icon/nav-menu.scss";

const NavMenu = () => {
    const { isLightMode } = useData();

    // Color states
    const textColor = isLightMode ? "black-text" : "white-text";
    const hoverColor = isLightMode ? "light-hover" : "dark-hover";

    // Local states
    const [showNav, setShowNav] = useState("hide-nav");
    const [isOpen, setIsOpen] = useState(false);
    const [prevScrollPos, setPreviousScrollPos] = useState(window.scrollY);

    // Hide nav bar and icon when scrolling down
    const handleScroll = useCallback(() => {
        const currentPos = window.scrollY;

        if (prevScrollPos > currentPos) {
            document.getElementById("nav-menu-bar").style.top = "0";
        } else {
            if (!isOpen) {
                document.getElementById("nav-menu-bar").style.top = "-60px";
            }
        }

        setPreviousScrollPos(currentPos);
    }, [prevScrollPos, isOpen]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        setShowNav(isOpen ? "show-nav" : "hide-nav");
    }, [isOpen]);

    return (
        <div className={`nav-menu-container ${showNav}${isLightMode ? " light-mode" : ""}`}>
            <div
                id="nav-menu-bar"
                className={`${prevScrollPos > 0 ? "nav-menu-bar" : ""}${
                    prevScrollPos > 0 && isLightMode ? " light-mode" : isLightMode ? "light-mode" : ""
                }`}
            >
                <svg
                    id="hamburger-menu"
                    className={`nav-menu${isLightMode ? " light-mode" : ""}`}
                    onClick={() => setIsOpen(!isOpen)}
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <line id="bottom-line" x1="5" y1="50.5" x2="56" y2="50.5" strokeWidth="2" />
                    <line id="middle-line" x1="5" y1="28.5" x2="56" y2="28.5" strokeWidth="2" />
                    <line id="top-line" x1="5" y1="6.5" x2="56" y2="6.5" strokeWidth="2" />
                    <line id="x-pos" x1="7.70711" y1="6.29289" x2="53.669" y2="52.2548" opacity="0" strokeWidth="2" />
                    <line id="x-neg" x1="6.29289" y1="52.2548" x2="52.2548" y2="6.29288" opacity="0" strokeWidth="2" />
                </svg>
            </div>
            <div className="nav-menu-items-container">
                <Logo isLightMode={isLightMode} />
                <div className="nav-menu-items">
                    <a
                        id="nav-home"
                        className={`${textColor} ${hoverColor}`}
                        href="#home"
                        onClick={() => setIsOpen(false)}
                    >
                        Home
                    </a>
                    <a
                        id="nav-about"
                        className={`${textColor} ${hoverColor}`}
                        href="#about"
                        onClick={() => setIsOpen(false)}
                    >
                        About
                    </a>
                    <a
                        id="nav-projects"
                        className={`${textColor} ${hoverColor}`}
                        href="#projects"
                        onClick={() => setIsOpen(false)}
                    >
                        Projects
                    </a>
                    <a
                        id="nav-contact"
                        className={`${textColor} ${hoverColor}`}
                        href="#contact"
                        onClick={() => setIsOpen(false)}
                    >
                        Contact
                    </a>
                </div>
                <div className="nav-menu-icon">
                    {!isLightMode && <Sun />}
                    {isLightMode && <Moon />}
                </div>
            </div>
        </div>
    );
};

export default NavMenu;
