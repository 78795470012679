import React from "react";

import { useData } from "../context/AppContext";

import "./github.scss";

const Github = () => {
    const { isLightMode } = useData();

    return (
        <svg
            className={`github-svg${isLightMode ? " light-mode" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27 27"
            onClick={() => window.open("https://github.com/yen213")}
        >
            <title>Github</title>
            <path d="M22.28,9.66a6.05,6.05,0,0,0,.34-2A5.59,5.59,0,0,0,22,5a5.74,5.74,0,0,0-2.38.47,12.91,12.91,0,0,0-2.36,1.46,15.49,15.49,0,0,0-3.54-.38A17.66,17.66,0,0,0,9.8,7,13.47,13.47,0,0,0,7.42,5.48,5.65,5.65,0,0,0,5,5a5.59,5.59,0,0,0-.64,2.63,6.17,6.17,0,0,0,.34,2.05A6.9,6.9,0,0,0,3,14.47a8.63,8.63,0,0,0,.78,4,4.9,4.9,0,0,0,1.09,1.39,5.58,5.58,0,0,0,1.52,1,13.3,13.3,0,0,0,1.73.62,10.69,10.69,0,0,0,1.93.36q1,.12,1.86.15c.54,0,1.14,0,1.79,0s1.48,0,2.11-.05A20.46,20.46,0,0,0,18,21.68a11.14,11.14,0,0,0,2.15-.57,6.89,6.89,0,0,0,1.78-1,4.89,4.89,0,0,0,1.33-1.61,8.82,8.82,0,0,0,.77-4A7,7,0,0,0,22.28,9.66Zm-1.51,8.78a3.46,3.46,0,0,1-1,1.25,5.11,5.11,0,0,1-1.54.72,10.3,10.3,0,0,1-1.77.36c-.56.05-1.19.08-1.88.08H12.44c-.69,0-1.32,0-1.88-.08a10.3,10.3,0,0,1-1.77-.36,5.11,5.11,0,0,1-1.54-.72,3.46,3.46,0,0,1-1-1.25,4,4,0,0,1-.4-1.85,3.63,3.63,0,0,1,.87-2.46,2.93,2.93,0,0,1,2.36-1c.36,0,1.18.09,2.46.26a13.43,13.43,0,0,0,2,.13,13.43,13.43,0,0,0,2-.13c1.3-.17,2.12-.26,2.46-.26a3,3,0,0,1,2.36,1,3.63,3.63,0,0,1,.87,2.46A4,4,0,0,1,20.77,18.44Z" />
            <path d="M10.38,14.68a1.25,1.25,0,0,0-.92-.41,1.21,1.21,0,0,0-.91.41A2.43,2.43,0,0,0,8,15.6a3.34,3.34,0,0,0,0,2,2.43,2.43,0,0,0,.55.92,1.21,1.21,0,0,0,.91.41,1.25,1.25,0,0,0,.92-.41,2.54,2.54,0,0,0,.54-.92,3.14,3.14,0,0,0,0-2A2.54,2.54,0,0,0,10.38,14.68Z" />
            <path d="M18.45,14.68a1.21,1.21,0,0,0-.91-.41,1.25,1.25,0,0,0-.92.41,2.54,2.54,0,0,0-.54.92,3.14,3.14,0,0,0,0,2,2.54,2.54,0,0,0,.54.92,1.25,1.25,0,0,0,.92.41,1.21,1.21,0,0,0,.91-.41,2.43,2.43,0,0,0,.55-.92,3.34,3.34,0,0,0,0-2A2.43,2.43,0,0,0,18.45,14.68Z" />
        </svg>
    );
};

export default Github;
