import React from "react";

import MyPicture from "../../asset/profile-photo-small.jpg";

import { useData } from "../../context/AppContext";

import "./home-styles.scss";

const Home = () => {
    const { isLightMode } = useData();

    const highlightColor = isLightMode ? "light-green-text" : "cyan-text";
    const textColor = isLightMode ? "black-text" : "white-text";

    return (
        <div id="home" className="home-container section-content">
            <div className="image-container pic">
                <img src={MyPicture} alt="myself" />
            </div>
            <p className={`${textColor} item1`}>Hi, my name is</p>
            <p className={`${textColor} item2`}>Maruf Hossain</p>
            <p className={`${textColor} item3`}>(but you can call me Yen)</p>
            <p className={`${highlightColor} item4`}>
                I am a software engineer with a passion for Full-Stack Web Development. I specialize in developing
                backend services and designing frontend applications.
            </p>
        </div>
    );
};

export default Home;
