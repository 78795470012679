import React from "react";

import Email from "icon/Email";
import Github from "icon/Github";
import Linkedin from "icon/Linkedin";
import Logo from "icon/Logo";
import Moon from "icon/Moon";
import Sun from "icon/Sun";

import { useData } from "context/AppContext";

import "./menu-styles.scss";

const Menu = () => {
    const { isLightMode } = useData();

    const textColor = isLightMode ? "black-text" : "white-text";
    const hoverColor = isLightMode ? "light-hover" : "dark-hover";

    return (
        <div className="menu">
            <div className="menu-logo">
                <Logo />
            </div>
            <div className="social">
                <Linkedin />
                <Github />
                <a href="mailto:hello@yenmaruf.com" rel="noopener noreferrer" target="_blank">
                    <Email />
                </a>
            </div>
            <ul>
                <li>
                    <a id="home-link" href="#home" className={`${textColor} ${hoverColor}`}>
                        Home
                    </a>
                </li>
                <li>
                    <a id="about-link" href="#about" className={`${textColor} ${hoverColor}`}>
                        About
                    </a>
                </li>
                <li>
                    <a id="projects-link" href="#projects" className={`${textColor} ${hoverColor}`}>
                        Projects
                    </a>
                </li>
                <li>
                    <a id="contact-link" href="#contact" className={`${textColor} ${hoverColor}`}>
                        Contact
                    </a>
                </li>
            </ul>
            <div className="menu-mode-change">
                {!isLightMode && <Sun />}
                {isLightMode && <Moon />}
            </div>
        </div>
    );
};

export default Menu;
