import React from "react";

import { useData } from "../context/AppContext";

import "../icon/sun.scss";

const Sun = () => {
    const { setIsLightMode } = useData();

    return (
        <svg
            className="sun-svg"
            onClick={() => setIsLightMode(true)}
            viewBox="0 0 60 60"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="11.8284" y1="11" x2="16.0711" y2="15.2426" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <line x1="11" y1="31" x2="5" y2="31" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <line x1="12" y1="50.2426" x2="16.2426" y2="46" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <line x1="31" y1="49" x2="31" y2="55" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <line x1="46.8284" y1="46" x2="51.0711" y2="50.2426" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <line x1="56" y1="31" x2="50" y2="31" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <line x1="46" y1="15.2426" x2="50.2426" y2="11" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <line x1="31" y1="5" x2="31" y2="11" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <circle cx="31" cy="30" r="15" fill="white" />
        </svg>
    );
};

export default Sun;
